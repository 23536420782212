html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

iframe {
  width: 100%;
}

input[type=submit] {
  cursor: pointer;
}

body {
  font: 12px/1.6 Arial, Helvetica, sans-serif;
  color: #6E6E6E;
  background:#fff;
}

h1 {
  color: #006b8c;
  font-weight: 400;
  margin: 5px 0;
}

h2 {
  color: #006b8c;
  font-size: 23px;
  font-weight: 400;
  line-height:1.3;
  @media(max-width:900px) { font-size:21px; }
  @media(max-width:600px) { font-size:19px; }
  @media(max-width:450px) { font-size:18px; }
}

#page-wrap {
  max-width: 992px;
  margin: 0 auto;
  width:90%;
}


#page-wrap-inner {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  background: url(../images/bck_main.jpg) repeat-x scroll center top #F3F5F4;
  transition: right 0.3s ease;
  min-height: 100vh;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
  &.push {
    right: 20.625em;
  }
  img {
    max-width:100%;
  }
}

#largeback {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

#page-header {
  position: relative;
  height: 185px;
}

#page-header-right {
  position: absolute;
  right: 0;
  width: 960px;
  height: 37px;
  top:0;
}

#page-header-signup {
  position: relative;
  float: right;
  width: 155px;
  top: 4px;
  @media(max-width:1080px) {
    display:none;
  }
}

.long {
  width: 180px !important;
}

#welcomemsg {
  color: #FFF;
  font-size: 12px;
  left: 131px;
  position: absolute;
  top: 8px;
  z-index: 10;
}

.btn_signup {
  background: url(../images/btn_signup.jpg) no-repeat center top;
  width: 73px;
  height: 26px;
  text-indent: -9999px;
  display: inline-block;
  position: relative;
}

.btn_login {
  background: url(../images/btn_login.jpg) no-repeat center top;
  width: 71px;
  height: 26px;
  text-indent: -9999px;
  display: inline-block;
  position: relative;
  margin: 0 10px 0 0;
}

#page-header-signup {
  #btn_signout {
    background: url(../images/btn_signout.jpg) no-repeat center top;
    width: 73px;
    height: 26px;
    text-indent: -9999px;
    display: block;
    position: relative;
    float: left;
    border: none;
  }
  #btn_membersarea {
    background: url(../images/btn_member.jpg) no-repeat center top;
    width: 91px;
    height: 26px;
    text-indent: -9999px;
    display: block;
    position: relative;
    float: left;
    margin: 0 10px 0 0;
  }
}

#page-wrap ul {
  &#nav {
    @media(max-width:1080px) {
      display:none;
    }
    font-size: 14px;
    line-height: 0;
    list-style: none outside none;
    position: absolute;
    right: 0;
    top: 142px;
    width: 694px;
    vertical-align: top;
    li {
      display: inline-block;
      position: relative;
      border-right: 1px solid #fff;
      a {
        display:inline-block;
        color: #fff;
        text-decoration: none;
        padding: 8px 13px;
      }
      &.right a {
        padding: 8px 0 8px 15px;
      }
    }
  }
  &#smallnav {
    @media(max-width:1080px) {
      display:none;
    }
    font-size: 12px;
    height: 10px;
    line-height: 1px;
    list-style: none outside none;
    position:absolute;
    right:275px;
    display:inline-block;
    top: 12px;
    li {
      border-right: 1px solid #FFF;
      display: inline-block;
      position: relative;
      padding: 0;
      a {
        color: #FFF;
        display:inline-block;
        text-decoration: none;
        border: 0;
        padding: 5px 10px;
      }
      &.right {
        border: 0;
        padding-right: 4px;
      }
      &.active {
        position: relative;
        text-decoration: underline;
      }
      &.facebook a, &.twitter a {
        width: 22px;
        height: 22px;
        text-indent: -9999px;
        border: none;
        position: relative;
        top: -10px;
        padding: 0;
      }
      &.facebook a {
        background: url(../images/btn_facebook.jpg) no-repeat center top;
      }
      &.twitter {
        a {
          background: url(../images/btn_twitter.jpg) no-repeat center top;
        }
        border: none;
        padding: 0 10px 0 0;
      }
      &.facebook {
        border: none;
        padding: 0 4px 0 0;
      }
    }
  }
}

#body-header {
  //background: url(../images/bck_bdhead.jpg) no-repeat center top;
  display:flex;
  border:1px solid #D2D2D2;
  border-radius:6px;
  overflow:hidden;
  #body-slideshow {
    //height: 287px;
    width: calc(100% - 333px);
    position: relative;
    #overlay {
      text-indent: -9999px;
      position: absolute;
      right: 0;
      top: 0;
      background: url(../images/img_welcome.png) no-repeat center top;
      width: 102px;
      height: 107px;
      z-index: 100;
    }
    /* Direction nav styles (e.g. Next & Prev) */
    .nivo-directionNav a {
      position:absolute;
      top:calc(50% - 20.5px);
      z-index:9;
      cursor:pointer;
      display:block;
      z-index:888;
    }
    .nivo-prevNav {
      left:-5px;
      width:20px; height:41px;
      background: url("../images/prev.png") no-repeat center top ;
      text-indent:-9999px;

    }
    .nivo-nextNav {
      right:-5px;
      width:20px; height:41px;
      background: url("../images/next.png") no-repeat center top ;
      text-indent:-9999px;
    }
    .nivo-controlNav {
      position: absolute;
      right:10px;
      bottom:0;
      z-index:9;
      cursor:pointer;
      a {
        z-index:9;
        cursor:pointer;
        background: url("../images/dot1.png") no-repeat center top ;
        text-indent:-9999px;
        width:14px;
        height:14px;
        margin:0 4px 0 0;
        display:inline-block;
        &.active {
          background: url("../images/dot2.png") no-repeat center top ;
        }
      }
    }
  }
  #intro {
    width:333px;
    padding: 30px 50px;
    background:#f6f6f6;
    .btnpink {
      margin:11px 0 0;
    }
  }
  @media(max-width:960px) {
    flex-direction:column;
    #body-slideshow {
      width:100%;
    }
    #intro {
      width:100%;
      text-align:center;
      .btnpink {
        margin:11px auto 0;
      }
    }
  }
  @media(max-width:600px) {
    #intro { padding:30px; }
  }
  @media(max-width:450px) {
    #intro { padding:30px 20px; }
  }
}

#mobile-burger-btn {
  display:none;
  margin:0;
  cursor: pointer;
  z-index: 100;
  text-align:center;
  transition: right 0.5s;
  background:transparent;
  padding:5px 10px;
  i {
    font-size:28px;
    position: relative;
    color:#fff;
    &:nth-child(2) {
      display:none;
    }
  }
  @media(max-width:1080px) {
    display: inline-block;
    position: fixed;
    right:20px;
    z-index: 9999;
    &.scrolled {
      top:10px;
      background:#00829D;
    }
  }
  &.menu-open {
    right:calc(20.625em + 20px);
  }
}
/* Mobile Menu */
#mobile-nav-items {
  display: block;
  position: fixed;
  top: 0;
  right: -20.625em;
  width: 20.625em;
  height: 100%;
  z-index: 50;
  text-align:left;
  background:#00829D;
  overflow: scroll;
  //box-shadow: -5px 0 20px 0 rgba(0,0,0,0.17);
  transition: right 0.3s ease;
  #mobile-nav-inner-items {
    position: absolute;
    width: 20.625em;
    top:0;
    right:0;
    padding:22.5px 25px;
  }
  ul.main-mobile-links {
    margin:0;
    padding:45px 0 0;
    background:url('../images/mobile_head_ico.png') no-repeat;
    li {
      color:#fff;
      display: block;
      text-align:left;
      border-bottom: 1px solid #fff;
      padding:17.5px 1px;
      font-size:15px;
      a {
        color:#fff;
        text-decoration: none;
      }
    }
  }
  ul.mobile-nav-btns {
    margin:20px 0;
    padding:0;
    vertical-align: top;
    li {
      display:inline-block;
      width:calc(50% - 7px);
      vertical-align: top;
      a, input {
        display:block;
        background:#D0124B;
        border-radius:8px;
        text-align:center;
        font-size:15px;
        font-weight:bold;
        color:#fff;
        text-decoration: none;
        height:34px;
        line-height:34px;
      }
      form {
        margin:0;
        padding:0;
        fieldset {
          margin:0;
          input {
            margin:0;
            border:0;
            padding:0;
            width:100%;
          }
        }
      }
      &:nth-child(1) {
        margin-right:10px;
        a {
          background:#7CAB37;
        }
      }
    }
  }
  .minor-mobile-links {
    color:#86C9D3;
    text-align:center;
    font-size:12.5px;
    a {
      color:#86C9D3;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .mobile-nav-items-close {
    position: absolute;
    top:20px;
    right:20px;
    cursor:pointer;
    i {
      font-size:34px;
      color:#fff;
    }
    &:active {
      top:21px;
    }
  }
}
body.opened #mobile-nav-items {
  right:0;
}

/* */

#intro h1 {
  font-size: 21px;
}

#homeh1 {
  font-size: 18px !important;
}

#body-left {
  width: calc(100% - 331px);
  display:inline-block;
  padding:20px;
  padding-left:0;
  @media(max-width:960px) {
    width:calc(100%);
    padding-right:0;
  }
}

.catspage {
  //background: url(../images/bck_catpage.png) no-repeat center top;
  overflow: hidden;
}

.fullpage {
  background: url(../images/bck_full.png) no-repeat center top;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow:hidden;
}

.catspage #body-right, .fullpage #body-right {
  background: none;
  display:inline-block;
  position: relative;
  padding:20px;
  border-top-right-radius: 6px;
  overflow:hidden;
  @media(max-width:960px) {
    //padding-left:0;
    margin:0 auto;
    display:block;
  }
  &:before {
    z-index:-50;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:400px;
    content: " ";
    background:url('../images/fade_right.png') center left no-repeat;
    @media(max-width:960px) {
      background:none;
    }
  }
  &:after {
    z-index:-100;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:400px;
    content: " ";
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.5) 75%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.5) 75%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    @media(max-width:960px) {
      display:none;
    }
  }
}

#body-main {
  display: flex;
  @media(max-width:960px) {
    flex-direction:column;
  }
}

.fullpage #body-left {
  width: calc(100% - 327px);
  display:inline-block;
  border-top-left-radius:7px;
  overflow:hidden;
  @media(max-width:960px) {
    width:calc(100%);
    display:block;
  }
}

.catspage #body-left {
  padding:0;
  //width: calc(100% - 317px);
  width: calc(100% - 249px);
  border-top-left-radius:6px;
  overflow:hidden;
  @media(max-width:960px) {
    width:100%;
    border-top-right-radius:7px;
  }
}

.catspage #facebookright {
  width: 136px;
  height: 72px;
  background: url(../images/btn_ft_facebook.jpg) no-repeat center top !important;
  position: relative;
  float: left;
  display: block;
  text-indent: -9999px;
  margin: 0 10px 30px 0;
}

#body-left {
  .panel-wrap {
    //overflow: hidden;
    position: relative;
    padding:20px;
    .panel-wrap-inner {
      padding: 20px;
      background: none repeat scroll 0 0 #FFF;
      border: 1px solid #D2D2D2;
      border-radius:3px;
      overflow:hidden;
    }
    .texture {
      &:after {
        z-index:-100;
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:400px;
        content: " ";
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.5) 75%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.5) 75%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
      }
    }
    ul li a {
      font-size: 11px;
      text-decoration: none;
      font-weight: 700;
      color: #0E49CE;
    }
    .panel-wrap-header {
      display:flex;
      h2 {
        flex-grow:1;
      }
      .btngreen {
        margin-top:3px;
        margin-bottom:13px;
      }
      @media(max-width:600px) {
        flex-direction: column;
        .btngreen {
          margin-left:auto;
          margin-right:auto;
          margin-top:15px;
        }
      }
    }
    &.business-panel-wrap {
      margin:0 0 28px;
      padding:0;
      .panel-wrap-inner {
        margin:0;
      }
    }
  }
  .panel-page {
    ul {
      float: right;
      list-style: none;
    }
    li {
      float: left;
      border: 1px #ccc solid;
      margin: 3px;
      padding: 3px;
    }
  }
  ul.select-panel {
    clear: both;
    list-style: none;
    overflow: hidden;
    margin: 20px 0;
    li {
      width: 26%;
      float: left;
      min-height: 100px;
      border: 1px #ccc solid;
      margin-right: 20px;
      padding: 2%;
    }
  }
  .panel-wrap ul.list-col {
    display:block;
    columns:3;
    @media(max-width:1100px) { columns:2; }
    @media(max-width:750px) { columns:1; }
    li {
      list-style: none;
      margin: 0 0 10px;
      padding: 0 0 0 35px;
    }
  }
  ul.two-columns-panels {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    li {
      width: 40%;
      border: 1px #CCC solid;
      float: left;
      min-height: 120px;
      margin: 3%;
      padding: 1%;
    }
  }
  p {
    margin-bottom: 15px;
  }
}

#body-right {
  width: 335px;
  position: relative;
  padding: 20px 0 20px 25px;
  display:inline-block;
  vertical-align: top;
  @media(max-width:960px) {
    margin:0 auto;
    background:none;
    padding-left:0;
    width:100%;
  }
  #weather {
    background: #FFF;
    border: 1px solid #D2D2D2;
    width:100%;
    position: relative;
    padding: 10px;
    h3 {
      font-size: 14px;
      margin: 0 0 7px;
    }
    ul {
      list-style: none;
      border: none;
      margin: 0;
    }
    li {
      border: none;
      float: left;
      line-height: 19px;
      position: relative;
      text-align: center;
      width: 69px;
      span, h4 {
        clear: both;
        color: #999;
        float: left;
        height: 22px;
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }
}

#facebookright {
  width: 136px;
  height: 72px;
  background: url(../images/btn_ft_facebook.jpg) no-repeat center top !important;
  display: inline-block;
  text-indent: -9999px;
  margin: 0 30px 30px 0;
}

#twitterright {
  width: 136px;
  height: 72px;
  background: url(../images/btn_ft_twitter.jpg) no-repeat center top !important;
  display: inline-block;
  text-indent: -9999px;
  margin: 0;
}

#districtguide {
  width: 302px;
  height: 115px;
  background: url(../images/img_district.jpg) no-repeat center top !important;
  position: relative;
  display: block;
  text-indent: -9999px;
  margin: 15px auto 5px;
  background-size:contain;
}

.right2 #facebookright {
  margin: 0 10px 30px 0 !important;
}

#body-right {
  background: url(../images/fade_right.png) no-repeat left top;
  @media(max-width:960px) {
    background:none;
  }
  .sub {
    position:relative;
    overflow:hidden;
    &:after {
      content: " ";
      background-image: url(../images/icon.jpg);
      background-position: right center;
      background-repeat:no-repeat;
      height:49px;
      position: absolute;
      width:109px;
      top:5px;
      right:5px;
      @media(max-width:365px) {
        content: "";
        display:none;
      }
    }
  }
  ul {
    list-style: none;
    margin-bottom: 20px;
  }
  #cats {
    li a {
      text-decoration: none;
      color: #003;
      padding: 0 0 0 16px;
    }
  }
}

#cats {
  background: #fff;
  border: 1px solid #D2D2D2;
  margin-bottom: 30px;
  position: relative;
  text-align:left;
  h3 {
    font-size: 28px;
    color: #006B8C;
    border-bottom: 1px solid #CCC;
    padding: 5px 10px;
  }
}

.memtitle {
  border-bottom: 1px solid #CCC;
  color: #006B8C;
  font-size: 22px !important;
  padding: 10px !important;
  position:relative;
  &:after {
    content: " ";
    background: url(../images/icon.jpg) no-repeat;
    width:109px;
    height:49px;
    position:absolute;
    top:5px;
    right:5px;
    @media(max-width:365px) {
      content: "";
      display:none;
    }
  }
}

#cats {
  ul {
    list-style: none;
    border-bottom: none;
    margin: 0;
  }
  li {
    line-height: 40px;
    border-bottom: 1px solid #CCC;
    a {
      display: block;
      text-decoration: none;
      color: #007BDC;
      &:hover {
        text-decoration: underline;
        background: none !important;
      }
    }
  }
}

#body-right li a.active {
  text-decoration: underline;
  background: none !important;
}

#buscats li {
  background: url(../images/ico_car.jpg) no-repeat scroll 11px 10px transparent;
  padding: 0 0 0 35px;
}

#place-your-ads {
  width: 100%;
  background: #fff;
  border: 1px solid #D2D2D2;
  padding: 10px 0 10px 10px;
  li {
    border: none !important;
    line-height: 0;
    overflow: hidden;
    position: relative;
    width: calc(50% - 12px);
    max-height: 99px;
    display:inline-block;
    margin: 0 10px 10px 0;
    text-align:center;
    a {
      line-height: 0;
      display: block;
      text-decoration: none;
      color: #003;
      background: none !important;
      padding: 0;
      text-align:center;
      &:hover {
        line-height: 0;
        background: none !important;
      }
    }
  }
  .moreadd {
    display:block;
    width:100%;
    height: 21px;
    position: relative;
    text-align: right;
    text-decoration: underline;
    margin: 0;
    a {
      color: #317BC0;
      font-size: 9px;
      font-style: italic;
      position: relative;
      text-align: right;
      text-decoration: underline;
      top: 11px;
    }
  }
}

.catspage #place-your-ads {
  background: #fff;
  border: 1px solid #D2D2D2;
  position: relative;
  padding: 10px 0 10px 10px;
}

.business-listings-wrapper {
  position:relative;
  padding:20px 0;
  &:after {
    z-index:-100;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:400px;
    content: " ";
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.5) 75%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.5) 75%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.5) 75%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  }
}

ul#list-business {
  list-style: none;
  position: relative;
  margin:0 20px;
  li {
    background: #FFF;
    border: 1px solid #D2D2D2;
    overflow: hidden;
    position: relative;
    margin: 0 0 20px;
    padding: 10px;
    vertical-align: top;
  }
  .left {
    width: 120px;
    display:inline-block;
    position: relative;
    overflow: hidden;
    margin: 0 20px 0 0;
    a {
      font-size: 9px;
      position: relative;
      text-align: center;
      width: 118px;
      padding: 5px 0;
    }
  }
  .img-wrap {
    width: 100%;
    overflow: hidden;
    height: 120px;
    margin: 0 0 10px;
    display:inline-block;
    vertical-align: top;
  }
  .txt-wrap {
    width: calc(100% - 145px);
    display:inline-block;
    vertical-align: top;
    .col-item a {
      color: #6E6E6E;
    }
    h2 {
      padding-right:140px;
      a {
        color: #006B8C;
        font-size: 24px;
        text-decoration: none;
      }
    }
    .col-item {
      position: relative;
      clear: both;
      width: 354px;
      margin: 0 0 10px;
      padding: 0 0 0 30px;
    }
    .phone {
      background: url(../images/ico_phone.jpg) no-repeat scroll left top transparent;
    }
    .email {
      background: url(../images/ico_email.jpg) no-repeat scroll left top transparent;
    }
    .web {
      background: url(../images/ico_web.jpg) no-repeat scroll left top transparent;
    }
    .show-only-on-mobile {
      display:none;
    }
  }
  @media(max-width:645px) {
    li {
      display:flex;
      flex-direction: column;
      .left {
        margin:0 20px 0 0;
        .img-wrap {
          height:auto;
        }
        .btngreen {
          display:none;
        }
      }
      .txt-wrap {
        width:calc(100%);
        h2 {
          padding-right:0;
        }
        .show-only-on-mobile {
          display:block;
          text-align:center;
          margin:10px 0;
        }
      }
    }
  }
}

#business-slideshow-wrap {
  clear: both;
  width:calc(100%);
  border: 2px solid #D2D2D2;
  margin:10px auto 20px;
  overflow:hidden;
  text-align:center;
}

#business-slideshow-wrap-controls {
  display:block;
  margin-bottom:35px;
  text-align:center;
  span {
    cursor:pointer;
    position:relative;
    &:active {
      top:1px;
    }
  }
}

#business-profile {
  overflow: hidden;
  margin: 0 0 20px;
  p {
    margin-bottom: 20px;
  }
}

#business-profile-col2 {
  background: #F4F4F4;
  width: 392px;
  border: 1px solid #D2D2D2;
  padding: 10px 20px 20px;
  display:inline-block;
  vertical-align: top;
  @media(max-width:900px) {
    width:100%;
    margin:0 0 20px;
  }
}

#business-profile-col1 {
  width: calc(100% - 416px);
  margin: 0 20px 0 0;
  display:inline-block;
  @media(max-width:900px) {
    width:100%;
    margin:0 0 20px;
  }
  img {
    float: left;
    padding: 0 30px 20px 0;
  }
  .fb {
    background: url(../images/btn_fb.jpg) repeat-x scroll left top;
    height: 38px;
    color: #fff;
    text-decoration: none;
    border: 1px solid #445983;
    position: relative;
    margin: 20px 10px 0 0;
    padding: 8px 15px 0 34px;
    display:inline-block;
  }
  .tw {
    background: url(../images/btn_tw.jpg) repeat-x scroll left top;
    height: 38px;
    color: #fff;
    text-decoration: none;
    border: 1px solid #7AC6F2;
    position: relative;
    margin: 20px 0 0;
    padding: 8px 15px 0 34px;
    display:inline-block;
    img {
      position: absolute;
      top: 0;
      left: 3px;
    }
  }
  .fb img {
    position: absolute;
    top: 0;
    left: 3px;
  }
  h2 {
    margin: 10px 0;
    padding: 0;
  }
}

#business-profile-col2 {
  h2 {
    font-size: 20px;
    margin: 0 0 10px;
    padding: 0;
  }
  #business-profile-col3 {
    width: 190px;
  }
}

#map_canvas {
  width: 180px;
  height: 180px;
  border: 2px solid #D2D2D2;
}

#map_canvas2 {
  width: 100%;
  height: 300px;
  border: 2px solid #D2D2D2;

}

#business-profile-col2 {
  vertical-align: top;
  .cdetails {
    width: 49%;
    vertical-align: top;
    display:inline-block;
    @media(max-width:510px) {
      width:100%;
      display:block;
    }
    #map_canvas {
      width:100%;
    }
    h3 {
      font-size: 15px;
    }
  }
  .adetails {
    width: 100%;
    position: relative;
    display:inline-block;
  }
  a {
    color: #619415;
    text-decoration: underline;
  }
}

.directions {
  width: 227px;
  text-align: center;
}

#tabs {
  overflow: hidden;
  position: relative;
  margin: 0 0 20px;
  ul#tab-items {
    display:flex;
    margin:0 20px;
    li {
    background: #F4F4F4;
    border: 1px solid #D2D2D2;
    border-left:0;
    color: #333;
    font-size: 15px;
    position: relative;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    &:nth-child(1) {
      border-left:1px solid #D2D2D2;
    }
  }
  @media(max-width:900px) {
    flex-direction: column;
    width:calc(100% - 40px);
    li {
      border-left:1px solid #D2D2D2;
      border-bottom:0;
      &.active {
        font-weight:bold;
      }
    }
  }
}
}

.tabspace {
  background: #FFF;
  border: 1px solid #D2D2D2;
  overflow: hidden;
  position: relative;
  top: -1px;
  border-radius: 0 6px 6px 6px;
  margin: 0 20px 30px;
  padding: 20px;
  @media(max-width:900px) {
    border-top-right-radius: 0;
    div.form-col {
      width:100%;
      display:block;
    }
  }
}

.leavereview {
  background: #FFF;
  border: 1px solid #D2D2D2;
  overflow: hidden;
  position: relative;
  width:calc(100% - 363px);
  padding: 20px;
  display:inline-block;
  margin-right:30px;
  margin-left:20px;
  vertical-align: top;

  @media(max-width:900px) {
    width:calc(100% - 40px);
    display:block;
    margin-left:20px;
    margin-right:20px;
  }
  .form-col {
    width:calc(100% - 20px);
  }
}

.leavereview-right-col {
  margin-right:20px;
  @media(max-width:900px) {
    margin-right:auto;
  }
}

a.btn-viewoffer {
  background: url(../images/btn_green.jpg) repeat-x #719E29;
  border: 1px solid #6D9430;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  text-shadow: 1px 1px 0 #5E9214;
  display: block;
  position: relative;
  padding: 5px 10px;
  border-radius:6px;
  text-align:center;
}

#tabs ul#tab-items li.active {
  background: #FFF !important;
  z-index: 10;
  border-top: 1px solid #D2D2D2 !important;
  border-right: 1px solid #D2D2D2 !important;
  border-bottom: #FFF;
  padding: 10px 20px 11px;
}

ul#thumb-selection li {
  width: calc(16.66% - 15px);
  display:inline-block;
  overflow: hidden;
  margin: 0 12px 12px 0;
  text-align:center;
  vertical-align: top;
  @media(max-width:1100px) {
    width:calc(20% - 15px);
  }
  @media(max-width:900px) {
    width:calc(25% - 15px);
  }
  @media(max-width:750px) {
    width:calc(33% - 15px);
  }
  @media(max-width:600px) {
    width:calc(50% - 15px);
  }
  @media(max-width:450px) {
    width:calc(100%);
  }
  .outter {
    border: 1px solid #D2D2D2;
    //height: 109px;
    overflow:hidden;
  }
  .img {
    height: 100px;
    overflow: hidden;
    padding: 3px;
    border:5px #fff solid;
  }
  img {
    min-height: 100px;
  }
  h4 {
    color: #333;
    text-align: center;
    //width: 125px;
  }
}

#list-reviews {
  ul {
    list-style: none !important;
  }
  li {
    border: 1px #ccc solid;
    border-left: 0;
    border-right: 0;
    list-style: none;
    position: relative;
    margin: 10px 0;
    padding: 10px;
  }
}

div.content {
  ul {
    margin: 0 0 0 20px;
  }
  a {
    color: #007BDC;
  }
}

#page-mid .small input {
  width: 221px;
}

ul#img-preview {
  li {
    border: 1px #ccc solid;
    margin: 0 0 20px;
    @media(max-width:750px) {
      text-align:center;
    }
    .imageholder {
      position: relative;
      overflow: hidden;
      max-width: 70px;
      height: 50px;
      display:inline-block;
      width:100%;
      @media(max-width:750px) {
        //display:block;
        //margin:0 auto;
        //width:auto;
        //height:auto;
        //text-align:center;
      }
    }
  }
  img {
    border: 1px #ccc solid;
  }
  form {
    max-width:calc(100% - 75px);
    display:inline-block;
    vertical-align:top;
    width:100%;
  }
  li {
    .imagetitle {
      top: 7px;
      width:100%;
      max-width: 146px;
      margin: 0 0 0 10px;
      display:inline-block;
      text-align:center;
    }
    .updatetitle {
      background: url(../images/btn_updatetitle.jpg) no-repeat;
      max-width: 107px;
      width:100%;
      height: 40px;
      text-indent: -9999px;
      display: inline-block;
      cursor: pointer;
      position: relative;
      border: none;
      top: 7px;
      margin: 0 0 0 10px;
    }
    .removeimage {
      background: url(../images/btn_deleteimg.jpg) no-repeat;
      width: 57px;
      height: 40px;
      text-indent: -9999px;
      display: inline-block;
      cursor: pointer;
      position: relative;
      border: none;
      top: 7px;
      margin: 0 0 0 10px;
      @media(max-width:750px) {
        margin-left:0;
      }
      @media(max-width:490px) {
        display:block;
        margin-left:auto;
        margin-right:auto;
        margin-top:10px;
      }
    }
    .mainimage {
      background: url(../images/btn_mainimg.jpg) no-repeat;
      width: 107px;
      height: 40px;
      text-indent: -9999px;
      display: inline-block;
      cursor: pointer;
      position: relative;
      border: none;
      top: 7px;
      margin: 0 0 0 10px;
      @media(max-width:750px) {
        margin-left:0;
      }
      @media(max-width:490px) {
        display:block;
        margin-left:auto;
        margin-right:auto;
        margin-top:10px;
      }
    }
    .active {
      background: url(../images/btn_mainimgactive.jpg) no-repeat;
      width: 107px;
      height: 40px;
      text-indent: -9999px;
      display: inline-block;
      cursor: pointer;
      position: relative;
      border: none;
      top: 7px;
      margin: 0 0 0 10px;
      @media(max-width:750px) {
        margin-left:0;
      }
      @media(max-width:490px) {
        display:block;
        margin-left:auto;
        margin-right:auto;
        margin-top:10px;
      }
    }
  }
}

#uploadform {
  background: none repeat scroll 0 0 #EEE;
  clear: both;
  border: 1px solid #CCC;
  margin: 0 0 20px;
  padding: 10px;
  fieldset {
    margin:0;
    padding:0;
    label {
      padding:0;
    }
  }
}

#uploading {
  background: #26A3D3;
  clear: both;
  position: absolute;
  width:100%;
  top: 0;
  left: 0;
  zoom: 1;
  filter: alpha(opacity = 50);
  opacity: 0.5;
  border: 1px solid #FFF;
  height: 181px;
  padding: 10px;
  img {
    left: 275px;
    position: absolute;
    top: 40px;
    margin: 0 auto;
  }
}

#uploadform .name {
  position: relative;
  display:inline-block;
  margin: 0 10px 0 0;
  vertical-align: top;
  @media(max-width:1050px) {
    display:block;
    margin:0 0 10px 0;
    width:100%;
  }
}

.imuploadleft {
  position: relative;
  display:inline-block;
  vertical-align: top;
  @media(max-width:1050px) {
    width:100%;
    display:block;
  }
}

.imuploadleft2 {
  width: 184px;
  position: relative;
  display:inline-block;
  vertical-align: top;
  @media(max-width:1050px) {
    width:calc(50% - 8px);
    display:inline-block;
    margin-right:5px;
  }
  @media(max-width:550px) {
    width:184px;
    margin-bottom:10px;
  }
}

.uploadbtn {
  background: url(../images/btn_green.jpg) repeat-x scroll 0 0 #719E29;
  border: 1px solid #6D9430;
  color: #FFF;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  text-shadow: 1px 1px 0 #5E9214;
  position: relative;
  top: 20px;
  padding: 8px 6px;
  display:inline-block;
  vertical-align: top;
  @media(max-width:1050px) {
    top:0;
    width:calc(50% - 8px);
    margin-left:5px;
  }
  @media(max-width:550px) {
    margin-left:0;
    width:auto;
  }
}

.SI-FILES-STYLIZED label.cabinet {
  width: 170px;
  height: 38px;
  background: url(../images/btn_imgupload.jpg) 0 0 no-repeat;
  display: block;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  top: 19px;
  @media(max-width:1050px) {
    top:0;
  }
  input.file {
    position: relative;
    height: 100%;
    width: auto;
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  }
}

#page-mid {
  form, fieldset {
    margin-bottom: 20px;
  }
  label {
    color: #333;
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 10px 0 3px;
    padding: 0 0 9px;
  }
}

.classpayments p {
  margin-bottom: 5px;
}

#classtotalprice {
  width:277px;
  height: 306px;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  background: url(../images/classtotal.jpg) 0 0 no-repeat;
  background-size:contain;
  top: 16px;
  margin: 0 0 20px;
  display:inline-block;
  .ctotal {
    color: #719E28;
    font-size: 50px;
    font-weight: 700;
    left: 17px;
    position: absolute;
    top: 34px;
  }
  @media(max-width:600px) {
    width:100%;
    display:block;
    max-width:277px;
    margin:0 auto 20px;
  }
}

.classpayments {
  width: calc(100% - 304px);
  display:inline-block;
  margin-right:20px;
  vertical-align: top;
  @media(max-width:600px) {
    width:100%;
    display:block;
    margin-right:0;
  }
}

#page-mid textarea {
  font-size: 14px;
}

input, select {
  border: 1px #CCC solid;
  font-size: 13px;
  position: relative;
  margin: 0 0 10px;
  padding: 10px;
}

textarea {
  border: 1px #CCC solid;
  font-size: 13px;
  position: relative;
  margin: 0 0 10px;
  padding: 10px;
  font: .85em/1.5 Arial, Helvetica, sans-serif;
}

div {
  &.form-col {
    input, textarea {
      width:100%;
    }

  }
  &.form-col2 {
    .pound {
      float: left;
      width: 20px;
      font-size: 20px;
      font-weight: 700;
    }
    .poundinput {
      float: right;
      width: 236px !important;
    }
  }
}

.smll_input {
  display:inline-block;
  width:calc(24.5% - 8px);
  margin-left:10px;
  &.first-col {
    margin-left:0;
  }
  @media(max-width:660px) {
    width:calc(50% - 7px);
    &.third-col {
      margin-left:0;
    }
  }
  @media(max-width:380px) {
    width:calc(100%);
    margin-left:0;
  }
  input {
    vertical-align:top;
    width:calc(100% - 11px);
  }
  .pound {
    display:inline-block;
    font-size: 20px;
    font-weight: 700;
    background: none repeat scroll 0 0 #EEE;
    border: 1px solid #CCC;
    border-right:0;
    text-align:center;
    width:20px;
    height:38px;
    line-height:38px;
    vertical-align:top;
  }
}

#page-mid .formtitle {
  color: #006B8C;
  display: block;
  font-size: 19px;
  font-weight: 700;
  text-transform: capitalize;
  width: 100%;
  margin: 50px 0 0;
  padding: 0 0 9px;
}

div {
  &.form-col {
    display:inline-block;
    width:calc(100% - 327px);
    vertical-align: top;
    @media(max-width:900px) {
      width:calc(100%);
      display:block;
    }
  }
  &.form-col h3 {
    font-size: 23px;
  }
  &.form-col-right {
    width: 295px;
    display:inline-block;
    vertical-align: top;
    margin-left:25px;
    @media(max-width:900px) {
      width:calc(100%);
      display:block;
      max-width:295px;
      margin-left:auto;
      margin-right:auto;
      margin-top:25px;
    }
  }
  &.date_wrap {
    display:flex;
    margin-left:-10px;
    select {
      width:calc(20%);
      margin-left:10px;
    }
    @media(max-width:600px) {
      display:block;
      margin-left:-10px;
      select {
        width:calc(32% - 10px);
        &:nth-child(4) {
          margin-left:10px;
        }
      }
    }
    @media(max-width:600px) {
      margin:0;
      select {
        width:100%;
        margin-left:0;
        &:nth-child(4) { display:inline-block; width:calc(50% - 7px); margin-left:0; }
        &:nth-child(5) { display:inline-block; width:calc(50% - 7px); margin-left:10px; }
      }
    }
  }
}

#page-footer-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
  //background: url(../images/bck_footer.jpg) repeat-x scroll center top #F3F5F4;
  margin: 30px 0 0;
}

#page-footer-top {
  color: #626669;
  padding: 20px 0;
  //background: url(../images/bck_footer.jpg);
  border-top:1px #E4E4E4 solid;
  background-color:#fff;
  border-bottom:1px #fff solid;
  .wrap {
    max-width:992px;
    width:90%;
    margin: 0 auto;
    display:flex;
  }
  #left {
    width: calc(100% - 300px);
    iframe {
      border-radius:5px;
      overflow:hidden;
      height:70px;
      max-width:210px;
      display:inline-block;
      margin:20px 30px 0 0;
      background:#D5D5D5;
      border:1px #D3D3D3 solid;
      width:100%;
      @media(max-width:600px) {
        margin:20px auto 0;
        max-width:210px;
        display:block;
      }
    }
  }
  #right {
    width:100%;
    max-width: 315px;
  }
  @media(max-width:990px) {
    .wrap {
      flex-direction: column;
      text-align:center;
    }
    #left, #right { width:100%; }
    #left {
      margin-bottom:30px;
    }
    #right {
      margin:0 auto;
    }
  }
}

#page-footer-btm {
  color: #626669;
  border-top:1px solid #F7F6F5;
  background:#DFDBD8;
  overflow: hidden;
  padding: 20px 0 40px;
  .wrap {
    max-width:992px;
    width:90%;
    margin: 0 auto;
    display:flex;
    @media(max-width:1000px) {
      flex-direction: column;
    }
  }
}

#credits {
  background:#DFDBD8;
  .wrap {
    padding:20px 0;
    display:flex;
    margin:0 auto;
    max-width:992px;
    width:90%;
    justify-content: space-between;
    align-content: space-between;
    border-top:1px #fff solid;
    @media(max-width:1000px) {
      display:block;
      text-align:center;
      img {
        margin-bottom: 10px;
      }
    }
  }
}


.weekday {
  position: relative;
  padding: 0 0 10px;
  strong {
    width: 100px;
    position: relative;
  }
}

#page-footer-top {
  #left {
    #facebook {
      width: 137px;
      height: 72px;
      background: url(../images/btn_ft_facebook.jpg) no-repeat center top;
      position: relative;
      display: inline-block;
      text-indent: -9999px;
      margin: 20px 30px 0 0;
      vertical-align: top;

      @media(max-width:380px) {
        margin:20px auto 0;
        display:block;
      }
    }
    #facebookA {
      width: 137px;
      height: 72px;
      background: url(../images/btn_ft_afacebook.jpg) no-repeat center top;
      position: relative;
      display: inline-block;
      text-indent: -9999px;
      margin: 20px 30px 0 0;
      vertical-align: top;
      @media(max-width:380px) {
        margin:20px auto 0;
        display:block;
      }
    }
    #twitter {
      width: 137px;
      height: 72px;
      background: url(../images/btn_ft_twitter.jpg) no-repeat center top;
      position: relative;
      display: inline-block;
      text-indent: -9999px;
      margin: 20px 30px 0 0;
      vertical-align: top;
    }
    #youtube {
      width: 137px;
      height: 72px;
      background: url(../images/btn_ft_youtube.jpg) no-repeat center top;
      position: relative;
      display: inline-block;
      text-indent: -9999px;
      margin: 20px 0 0;
      vertical-align: top;
    }
  }
  h4 {
    font-size: 24px;
    font-weight: 400;
    @media(max-width:900px) { font-size:21px; }
    @media(max-width:600px) { font-size:19px; }
    @media(max-width:450px) { font-size:18px; }
  }
  #right {
    text-align:left;
    h4 {
      position:relative;
      &:after {
        content: " ";
        background: url(../images/foterico.jpg) no-repeat top right transparent;
        position: absolute;
        top:5px;
        right:5px;
        width:85px;
        height:38px;
      }
    }
    a {
      background: url(../images/btn_green.jpg) repeat-x #719E29;
      border: 1px solid #6D9430;
      color: #fff;
      text-decoration: none;
      font-weight: 700;
      cursor: pointer;
      text-shadow: 1px 1px 0 #5E9214;
      display: inline-block;
      position: relative;
      padding: 0 10px;
      margin: 20px 0 0;
      border-radius:6px;
      width:100%;
      text-align:center;
      height:38px;
      line-height:35px;
      font-size:15px;
    }
  }
}

#page-footer-btm {
  h5 {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 7px;
  }
  .links {
    margin-right:10px;
    width:100%;
    max-width:calc(50% - 106px);
    @media(max-width:1000px) {
      margin-bottom:30px;
      max-width:none;
    }
    @media(max-width:450px) {
      text-align:center;
      margin-right:0;
    }
    ul {
      list-style: none outside none;
      &.two-cols {
        columns:2;
        @media(max-width:450px) {
          columns:1;
        }
      }
      li {
        a {
          text-decoration: none;
          color: #333;
        }
      }
    }
  }
  .sml {
    width: 192px;
    margin-right:0;
    @media(max-width:450px) {
      width:100%;
    }
  }
}

.validation {
  background: url(../images/valmsg.jpg) no-repeat scroll 10px 4px #FEEFCB;
  border: 1px solid #D3A350;
  color: #333;
  position: relative;
  border-radius: 6px;
  margin: 0 auto 20px;
  padding: 15px 10px 15px 60px;
}

#calsfrm .validation {
  width: 486px;
}

.rndCorner {
  border-radius: 6px;
}

.rndCorner2 {
  border-radius: 4px;
}

.rndleft {
  border-radius: 6px 0 0 0;
}

.rndright {
  border-radius: 0 6px 0 0;
}

.rndtitle {
  border-radius: 6px 6px 0 0;
}

.shadow {
  box-shadow: #666 0 0 3px;
}

body#image-uploader-body {
  background: #fff;
  overflow: hidden;
}

.btngreen {
  background: url(../images/btn_green.jpg) repeat-x #719E29;
  border: 1px solid #6D9430;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  text-shadow: 1px 1px 0 #5E9214;
  display: inline-block;
  position: relative;
  padding: 5px 10px;
}

.btngreen2 {
  background: url(../images/btn_green2.jpg) repeat-x center top;
  border: 1px solid #6D9430;
  color: #FFF;
  display: block;
  font-weight: 700;
  //left: 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  //top: 12px;
  //width: 242px;
  text-shadow: 1px 1px 0 #5E9214;
  padding: 7px 0;
  margin:20px;
}

.btnpink {
  background: url(../images/btn_pink.jpg) repeat-x scroll center top #CD0045;
  border: 1px solid #AD002E;
  color: #FFF;
  display: block;
  font-weight: 700;
  position: relative;
  text-align: center;
  text-decoration: none;
  max-width: 233px;
  width:100%;
  margin: 20px 0 0;
  padding: 6px 0;
}

.btnpink2 {
  background: url(../images/btn_pink.jpg) repeat-x center top;
  border: 1px solid #AD002E;
  color: #FFF;
  display: block;
  font-weight: 700;
  position: relative;
  text-align: center;
  text-decoration: none;
  margin: 10px 0 0;
  padding: 5px 0;
}

.busjoin, .memjoin {
  border: 1px solid #CCC !important;
  position: relative;
  margin: 0 0 20px;
  padding: 10px;
  position:relative;
  background:#fff;
  &:after {
    background: url(../images/back_face.jpg) no-repeat right top #FFF !important;
    width:85px;
    height:39px;
    position: absolute;
    top:5px;
    right:5px;
    content: " ";
    @media(max-width:365px) {
      content: "";
      display:none;
    }
  }
}

.busjoin, .suggest {
  //background: url(../images/back_face.jpg) no-repeat scroll 191px 7px #FFF !important;
  background:#fff;
  border: 1px solid #CCC !important;
  position: relative;
  margin: 0 0 20px;
  padding: 10px;
  &:after {
    background: url(../images/back_face.jpg) no-repeat right top #FFF !important;
    width:85px;
    height:39px;
    position: absolute;
    top:5px;
    right:5px;
    content: " ";
    @media(max-width:365px) {
      content: "";
      display:none;
    }
  }
}

.busjoin h3, .memjoin h3, .suggest h3 {
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 4px;
}

.busjoin p, .memjoin p, .suggest p {
  margin-bottom: 5px !important;
}

.btn_packages {
  background: url(../images/btn_packages.jpg) no-repeat center top;
  width: 133px;
  height: 26px;
  text-indent: -9999px;
  display: block;
  position: relative;
}


.membtns {
  position: relative;
  margin: 20px 20px 0;
  clear:both;
  .busjoin,
  .memjoin {
    width:calc(50% - 10px);
    display:inline-block;
  }
  .busjoin { margin-right:16px; }

  @media(max-width:735px) {
    .busjoin,
    .memjoin {
      width:100%;
    }
    .busjoin {
      margin-right:0;
    }
  }
}

.membtns2 {
  position: relative;
  width: 289px;
  display:inline-block;
  vertical-align: top;
  @media(max-width:900px) {
    margin-left:auto;
    //max-width:289px;
    width:100%;
    margin-top:40px;
    display:block;
    margin-right:20px;
    margin-left:20px;
    .busjoin,
    .memjoin {
      width:calc(50% - 40px);
      display:inline-block;
     }
     .busjoin {
       margin-right:35px;
     }
  }
  @media(max-width:700px) {
    margin-top:0;
    .busjoin,
    .memjoin {
      width:calc(100% - 40px);
     }
     .busjoin {
       margin-right:0;
       margin-bottom:35px;
       margin-top:35px;
     }
  }
}

.panel-small-home {
  background: #FFF;
  border: 1px solid #D2D2D2;
  width: 313px;
  position: relative;
  float: left;
  margin: 0 0 20px;
  h2 {
    border-bottom: 1px solid #D2D2D2;
    width: 293px;
    padding: 10px;
  }
}

#homeleft {
  //width: 303px;
  width:50%;
  vertical-align: top;
  display:inline-block;
  margin: 0 35px 0 0;
  @media(max-width:760px) {
    width:100%;
    margin-right:0;
    margin-bottom:20px;
  }
}

#homeright {
  width:calc(50% - 40px);
  vertical-align: top;
  display:inline-block;
  vertical-align:top;
  @media(max-width:760px) {
    width:calc(100%);
    display:block;
    .busjoin, .suggest, .memjoin {
      width:calc(50% - 20px);
      display:inline-block;
      vertical-align:top;
    }
    .memjoin {
      margin-left:35px;
    }
  }
  @media(max-width:670px) {
    .busjoin, .suggest, .memjoin {
      width:calc(100%);
      display:block;
      margin-bottom:0;
    }
    .memjoin {
      margin-left:0;
      margin-top:20px;
    }
  }
}

#hometabs {
  background: #FFF;
  border: 1px solid #D2D2D2;
  //width: 284px;
  width:calc(50% - 39px);
  position: relative;
  vertical-align: top;
  display:inline-block;
  margin: 0 0 20px;
  border-top-left-radius: 3px;
  border-top-right-radius:3px;
  overflow:hidden;
  .list-wrap {
    display: block;
    min-height: 493px;
  }
  ul li a {
    display: block;
    overflow: hidden;
  }
  @media(max-width:760px) {
   width:calc(100%);

  }
}

#body-right .latestevents {
  background: #FFF;
  border: 1px solid #D2D2D2;
  overflow: hidden;
  position: relative;
  margin: 0 0 20px;
  ul li a {
    display: block;
    overflow: hidden;
    padding: 0 !important;
  }
  h2 {
    color: #006B8C;
    font-size: 20px;
    font-weight: 400;
    border-bottom: 1px solid #D2D2D2;
    padding: 5px 10px;
  }
}

#hometabs {
  .nav {
    overflow: hidden;
    display:flex;
    align-items:stretch;
    align-content:stretch;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width:100%;
    li {
      display:block;
      width:calc(33.333333%);
      a {
        background: #CD0046;
        border-left: 1px solid #D2D2D2;
        font-size: 15px;
        position: relative;
        color: #fff;
        text-decoration: none;
        text-align: center;
        padding: 11px 0;
        display:block;
      }
      &:nth-child(1) {
        a {
          border-left:0;
          border-top-left-radius: 3px;
        }
      }
      &:nth-child(3) {

      }
    }
  }
  li a.current {
    background: #fff !important;
    border-bottom: 1px solid #fff !important;
    overflow: hidden;
    color: #333;
    &:hover {
      background: #fff !important;
      border-bottom: 1px solid #fff !important;
      overflow: hidden;
      color: #333;
    }
  }
}

div.offers-wrap {
  border-radius: 6px;
  width:100%;
  border: 1px #ccc solid !important;
  background: #FFF !important;
  overflow: hidden;
  margin-bottom: 12px;
  padding: 0 0 7px;
  .titlepink {
    width:100%;
    display:block;
    height: 42px;
    position: relative;
    background: #CD0046;
    margin: 0 0 10px;
  }
}

.classified-wrap .titlepink {
  width: 100%;
  height: 42px;
  position: relative;
  background: #CD0046;
  margin: 0 0 10px;
}

div {
  &.offers-wrap h2 {
    color: #fff;
    padding:6px 10px;
  }
  &.offers-wrap-page, &.offers-wrap-page2 {
    position:absolute;
    right:8px;
    top:13px;
  }
  &.offers-wrap-page a, &.offers-wrap-page2 a {
    background: url(../images/dot1.png) no-repeat scroll center top transparent;
    cursor: pointer;
    display:inline-block;
    height: 14px;
    position: relative;
    text-indent: -9999px;
    width: 14px;
    z-index: 9;
    margin: 0 2px 0 0;
  }
  &.classified-wrap {
    border: 1px #CCC solid;
    background: #FFF;
    overflow: hidden;
    padding: 0 0 11px !important;
    .titlepink {
      h2 {
        color: #fff;
        padding: 6px 10px;
      }
      .offers-wrap-page2 {
        a { display:inline-block; }
      }
    }
    img {
      width: 100%;
      margin: 0 auto;
    }
    ul {
      list-style: none;
      width: 70%;
      margin: 10px auto 0;
    }
    h3 {
      font-size: 16px;
      p {
        font-size: 11px;
      }
    }
    a {
      color: #6E6E6E;
      display: block;
      text-align: center;
      text-decoration: none;
    }
    .btn-viewclass {
      display: inline-block;
      width: calc(50% - 6px);
      background: url(../images/btn_green.jpg) repeat-x #719E29;
      border: 1px solid #6D9430;
      color: #fff;
      text-decoration: none;
      font-weight: 700;
      cursor: pointer;
      text-shadow: 1px 1px 0 #5E9214;
      position: relative;
      padding: 5px 10px;
      border-radius: 6px;
      text-align: center;
      margin-right:4px;
    }
    .btn_viewallclass {
      background: url(../images/btn_blue.jpg) repeat-x #2a6698;
      display: inline-block;
      width:calc(50% - 6px);
      border: 1px solid #2e5d89;
      color: #fff;
      text-decoration: none;
      font-weight: 700;
      cursor: pointer;
      text-shadow: 1px 1px 0 #2b6ca6;
      padding: 5px 10px;
      border-radius: 6px;
      text-align: center;
      margin-left:4px;
    }
    li {
      width: 280px;
      overflow: hidden;
      border: 0;
      background: #FFF;
      vertical-align: top;
    }
    .img-wrap {
      width: 125px;
      overflow: hidden;
      height: 95px;
      margin: 10px 0;
      vertical-align: top;
      display:inline-block;
    }
    .txt-wrap {
      display:inline-block;
      width: calc(110% - 180px);
      margin: 10px 10px 0;
      padding: 0 5px 5px;
      vertical-align: top;
    }
    .txt-wrap p {
      font-size: 30px !important;
      color: #86b43e !important;
      margin: 0 !important;
    }
  }
}

#busoffers .offers-wrap {
  border: none !important;
  padding: 20px 0 0;
  li {
    border: 1px solid #D2D2D2 !important;
    width: 292px;
    height: 211px;
    position: relative;
    list-style: none;
    margin: 0 20px 20px 0;
    padding: 6px;
    display:inline-block;
  }
}

div {
  &.offers-wrap-page a.activeSlide, &.offers-wrap-page2 a.activeSlide {
    background: url(../images/dot2.png) no-repeat scroll center top transparent;
  }
}

ul {
  &.cycle-panels {
    clear: both;
    width: 280px;
    list-style: none outside none;
    margin: 0 auto;
  }
  &.cycle-panels2 {
    clear: both;
    width: 280px;
    margin: 0 auto;
  }
}

div.offers-wrap {
  a {
    text-decoration: none;
  }
  .btn-viewoffer {
    width:calc(50% - 6px);
    display:inline-block;
    margin-right: 4px;
  }
  .btn_viewall {

    background: url(../images/btn_blue.jpg) repeat-x #2a6698;
    display: inline-block;
    width: calc(50% - 6px);
    border: 1px solid #2e5d89;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    text-shadow: 1px 1px 0 #2b6ca6;
    padding: 5px 10px;
    border-radius: 6px;
    text-align: center;
    margin-left: 4px;

  }
  li {
    display:block;
    width:100%;
    overflow: hidden;
    border: none !important;
  }
  .img-wrap {
    width: 125px;
    display:inline-block;
    overflow: hidden;
    height: 105px;
    vertical-align: top;
    position: relative;
    margin: 0 10px 10px 0;
    @media(max-width:680px) {
      width:calc(100%);
      display:block;
      margin-right:0;
      text-align:center;
      img {
        height:105px;
      }
    }
  }
  .txt-wrap {
    width:calc(100% - 145px);
    display:inline-block;
    position: relative;
    padding: 0 10px 10px;
    vertical-align: top;
    p {
      font-size: 30px !important;
      color: #86b43e !important;
    }

    @media(max-width:680px) {
      width:calc(100%);
      display:block;
    }
  }
  .pricing-details {
    clear: both;
    border: 1px #ccc solid;
    overflow: hidden;
    position: relative;
    margin: 0 auto 12px;
    display:flex;
    @media(max-width:400px) {
      flex-direction:column;
    }
    div {
      height: 40px;
      @media(max-width:400px) {
        height:50px;
        padding-top:5px;
      }
      text-align: center;
      width:calc(33.333%);
      @media(max-width:400px) {
        width:100%;
        text-align:center;
        display:block;
      }
      h5 {
        font-weight: 700;
        color: #666 !important;
        text-align: center;
      }
      h4 {
        font-size: 16px;
        color: #333 !important;
        text-align: center;
        font-weight: 700;
      }
    }
    .side-border {
      border: 1px #ccc solid;
      border-top: 0;
      border-bottom: 0;
      @media(max-width:400px) {
        border: 1px #ccc solid;
        border-left:0;
        border-right:0;
      }
    }
  }
}

.seperator {
  width: 100%;
  border-bottom: 1px #ccc solid;
  clear: both;
  margin: 0 6px 0 0;
}

div.view-btns {
  margin-bottom: 10px;
}

.btn-blue-large {
  background: url(../images/btn_blue_large.jpg) no-repeat;
  display: block;
  width: 133px;
  height: 37px;
  line-height: 37px;
  color: #FFF;
  float: right;
  text-align: center;
  text-decoration: none;
}

.btn-green-large {
  background: url(../images/btn_green_large.jpg) no-repeat;
  display: block;
  width: 133px;
  height: 37px;
  line-height: 37px;
  color: #FFF;
  float: left;
  text-align: center;
  text-decoration: none;
}

#page-mid .catspage {
  .titlearea {
    //height: 77px;
    overflow: hidden;
    position: relative;
    background-color:#cbe8f0;
    min-height:97px;
      form {
        margin-bottom:0;
        fieldset {
          margin-bottom:0;
        }
      }
    padding: 20px;
    .titlearea-inner {
      display:inline-block;
      width:calc(100% - 175px);
      vertical-align: top;
      @media(max-width:640px) {
        width:calc(100%);
      }
    }
    h1 {
      text-shadow: 1px 1px 0 #fff;
      //width: 471px;
      font-weight: 700;
      font-size: 22px;
      margin: 0;
      line-height:1.3;
      @media(max-width:900px) { font-size:20px; }
      @media(max-width:600px) { font-size:18px; }
      @media(max-width:450px) { font-size:17px; }
    }
    h2 {
      text-shadow: 1px 1px 0 #fff;
      //width: 471px;
      font-weight: 700;
      font-size: 16px;
      margin: 0;
      line-height:1.3;
      @media(max-width:900px) { font-size:20px; }
      @media(max-width:600px) { font-size:18px; }
      @media(max-width:450px) { font-size:17px; }
    }
    .sort {
      width:165px;
      display:inline-block;
      vertical-align: top;
      margin-top:10px;
      text-align:right;
      select {
        width:100%;
        max-width:215px;
      }
      @media(max-width:640px) {
        width:calc(100%);
        display:block;
        text-align:center;
        max-width:none;
        select {
          max-width:none;
        }
      }
    }
  }
}

.fullpage .titlearea {
  overflow: hidden;
  position: relative;
  width: calc(100% - 20px);
  margin: 0 10px 20px;
  padding: 20px;
  @media(max-width:900px) {
    margin-left:0;
    margin-right:0;
    width:calc(100%);
    display:block;
    text-align:center;
  }
  background:#cbe8f0;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height:1.3;
    @media(max-width:900px) { font-size:21px; }
    @media(max-width:600px) { font-size:19px; }
    @media(max-width:450px) { font-size:18px; }
  }
  .sort {
    position: absolute;
    top: 28px;
    right: 21px;
    @media(max-width:900px) {
      position: static;
      margin-top:10px;
      text-align:center;
      display:block;
    }
  }
}

.details {
  #sub-item-img {
    display:inline-block;
    width: 150px;
    height: 143px;
    overflow: hidden;
    margin-right: 10px;
    vertical-align:top;
    @media(max-width:600px) {
      margin-right:0;
      width:100%;
      height:auto;
      display:block;
    }
  }
  #sub-item-txt {
    vertical-align:top;
    display:inline-block;
    width:calc(100% - 170px);
    padding: 0 0 20px 20px;
    @media(max-width:600px) {
      width:calc(100%);
      padding-left:0;
    }
    div {
      vertical-align:top;
      display:inline-block;
      width:calc(49%);
      @media(max-width:700px) {
        width:calc(100%);
        display:block;
      }
      font-size: 11px;
      p {
        margin: 0 0 5px;
      }
    }
  }
  h3 {
    font-size: 20px;
    line-height:1.3;
    @media(max-width:900px) { font-size:19px; }
    @media(max-width:600px) { font-size:18px; }
    @media(max-width:450px) { font-size:17px; }
    text-transform: capitalize;
  }
}

.package {
  background: url(../images/bck_pt3.jpg) repeat-x top center #FFF;
  border: 1px solid #CCC;
  float: left;
  height: 367px;
  overflow: hidden;
  position: relative;
  width: 201px;
  margin: 0 27px 0 0;

  @media(max-width:1050px) {
    width:calc(33.33333% - 20px);
    margin:0 10px 30px;
  }

  @media(max-width:760px) {
    width:calc(100% - 20px);
    margin:0 auto 30px;
    max-width:310px;
    float:none;
  }
  h2 {
    color: #333;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    padding: 163px 0 0;
  }
}

.steps {
  color: #333;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  padding: 10px 0 0;
}

.permonth {
  color: #333;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  padding: 0;
}

.bronze {
  background: url(../images/bck_pt2.jpg) repeat-x top center #FFF;
}

.gold {
  background: url(../images/bck_pt4.jpg) repeat-x top center #FFF;
}

.package li {
  width: 100%;
  text-align: center;
  list-style: none;
  font-size: 15px;
  background: url(../images/bck_package.jpg) no-repeat top center;
  padding: 10px 0;
}

.contactinfo {
  background: none repeat scroll 0 0 #FFFCD7;
  border: 1px solid #E0BB3E;
  float: left;
  position: relative;
  width: 250px;
  padding: 20px;
  @media(max-width:1050px) {
    width:calc(50% - 20px);
  }
  @media(max-width:760px) {
    width:calc(100%);
    margin-right:0;
    margin-left:0;
    float:none;
    display:block;
  }
  h4 {
    font-size: 23px;
    color: #333;
  }
  a, .blue {
    color: #3F8CD8;
    text-decoration: none;
  }
  strong {
    font-size: 16px;
    position: relative;
    padding: 5px 0 0;
    display:inline-block;
  }
}

.extra {
  background: none repeat scroll 0 0 #FFF;
  border: 1px solid #D2D2D2;
  float: left;
  text-align: center;
  width: 250px;
  margin: 0 0 15px;
  padding: 19px 20px;
  @media(max-width:1050px) {
    width:calc(50% - 10px);
    margin:0 10px 15px;
  }
  @media(max-width:760px) {
    width:calc(100%);
    margin-right:0;
    margin-left:0;
    float:none;
  }
}

.bustitle {
  font-size: 33px !important;
}

.intropackage {
  position: relative;
  background: url(../images/fade_bm.jpg) no-repeat bottom center;
  overflow: hidden;
  margin: 0 0 25px;
  padding: 0 20px 25px;
  .packagevid {
    background: url(../images/packages_vid.jpg) no-repeat top center;
    overflow: hidden;
    position: relative;
    width: 244px;
    height: 84px;
    text-indent: -9999px;
    margin: 0 0 30px;
  }
  .mindmap {
    position: relative;
    max-width: 350px;
    display:inline-block;
    width:100%;
    @media(max-width:900px) {
      display:block;
      margin:0 auto;
    }
  }
  .intro {
    width:calc(100% - 355px);
    display:inline-block;
    padding: 0 30px 30px 0;
    vertical-align: top;
    p {
      padding: 0 0 20px;
    }
    @media(max-width:900px) {
      width:calc(100%);
      padding-right:0;
    }
  }
  .quote {
    vertical-align: top;
    font-size: 18px;
    width:100%;
    max-width:259px;
    @media(max-width:900px) {
      max-width:none;
      padding-right:0;
    }
  }
}

.package .pricing-wrap .btngreen {
  float: left;
  left: 60px;
  position: relative;
  text-align: center;
  font-size: 14px;
  margin: 10px 0 20px;
  padding: 5px 30px;
}

.inner-wrap {
  height: 300px;
  padding: 40px 10px;
}

.pricing-wrap {
  width: 100%;
  text-align: center;
  font-size: 29px;
  font-weight: 700;
  overflow: hidden;
  color: #333;
  height: 34px;
  margin:10px 0 0;
}

.package .btngreen {
  position: relative;
  text-align: center;
  margin: 10px auto 0;
  display:block;
  width:calc(100% - 20px);
}

.pricing-wrap span {
  font-size: 14px;
  font-weight: 700;
}

#comments {
  list-style: none;
  position: relative;
}

#commentsstyle {
  list-style: none;
  position: relative;
  padding: 10px 0 0;
  .star_wrap {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 75px;
  }
}



#comments {
  #commentsstyle li {
    @media(max-width:900px) {
      max-width:none;
    }
    .comment {
      background: #EDF7FB;
      border: 2px solid #C6E4EF;
      position: relative;
      padding: 10px;
    }
    h4 {
      background: url(../images/bck_comment.jpg) no-repeat 25px top;

      position: relative;
      top: -2px;
      color: #6E6E6E;
      padding: 7px 0 0 49px;
    }
    .name {
      background: url(../images/bck_comment.jpg) no-repeat 25px top;
      position: relative;
      top: -2px;
      color: #6E6E6E;
      padding: 7px 0 0 49px;
      @media(max-width:580px) {
        padding-left:10px;
        padding-right:10px;
        padding-top:22px;
      }
    }
  }
  h5 {

    color: #006B8C;
    font-size: 24px;
  }
}

#commentsstyle .btns {
  position: absolute;
  top: 9px;
  right: 96px;
  width: 272px;
  @media(max-width:700px) {
    right:9px;
    top:35px;
  }
  @media(max-width:580px) {
    position:static;
    top:auto;
    width:100%;
    text-align:center;
    margin-top:10px;
  }
  form {
    .approve_review {
      background: url(../images/btn_approve1.jpg) no-repeat;
      position: relative;
      width: 70px;
      height: 19px;
      display: inline-block;
      text-indent: -9999px;
      cursor: pointer;
      border: none;
    }
    .delete_review {
      background: url(../images/btn_delete1.jpg) no-repeat;
      position: relative;
      width: 60px;
      height: 19px;
      display: inline-block;
      text-indent: -9999px;
      cursor: pointer;
      border: none;
    }
    .keep {
      background: url(../images/btn_keep1.jpg) no-repeat;
      position: relative;
      width: 125px;
      height: 19px;
      display: inline-block;
      text-indent: -9999px;
      cursor: pointer;
      border: none;
    }
    .approve_review2 {
      background: url(../images/btn_approve2.jpg) no-repeat;
      position: relative;
      width: 70px;
      height: 19px;
      display: inline-block;
      text-indent: -9999px;
      cursor: pointer;
      border: none;
    }
    .keep2 {
      background: url(../images/btn_keep2.jpg) no-repeat;
      position: relative;
      width: 125px;
      height: 19px;
      display: inline-block;
      text-indent: -9999px;
      cursor: pointer;
      border: none;
    }
    &.delete_review {
      width: 62px;
      height: 19px;
      display: inline-block;
    }
    input[type="submit"] {
      position: absolute;
      top:0;
      left:0;
     }
  }
  fieldset.delete_review {
    width: 62px;
    height: 19px;
    display: inline-block;
  }
  form.approve_review, fieldset.approve_review {
    width: 73px;
    height: 19px;
    display: inline-block;
  }
  form.keep, fieldset.keep {
    width: 125px;
    height: 19px;
    display: inline-block;
  }
}

#pagination {
  clear:both;
  margin: 0 20px;
  text-align:center;
  li {
    display:inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .active-page {
    background: url(../images/btn_pink.jpg) repeat-x scroll center top;
    border: 1px solid #AD002E;
    color: #FFF;
  }
}

#classified-img {
  overflow: hidden;
  background: #F4F4F4;
  width: 314px;
  float: right;
  border: 1px solid #D2D2D2;
  position: relative;
  padding: 10px;
  img {
    width: 310px;
  }
  @media(max-width:700px) {
    float:none;
    width:100%;
    text-align:center;
    margin-top:0;
    margin-bottom:25px;
  }
}

#classified-thumbs {
  span {
    border: 2px solid #CCC;
    cursor: pointer;
    float: left;
    height: 75px;
    overflow: hidden;
    width: 90px;
    margin: 12px 16px 0 0;
    &:last-child {
      margin: 12px 0 0;
    }
  }
  img {
    min-height: 75px;
    max-width: 100px;
    margin: 0;
    padding: 0;
  }
}

#mainImg {
  min-height: 150px;
  border: 2px #CCC solid;
}

#classified-form {
  background: #F4F4F4;
  border: 1px solid #D2D2D2;
  display: none;
  margin: 0 0 30px;
  padding: 10px;
  width:100%;
  position:relative;
}

#close {
  background: url(../images/btn_close.jpg) no-repeat;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 79px;
  height: 26px;
  display: block;
  text-indent: -9999px;
  cursor: pointer;
  @media(max-width:600px) { width:30px; }
}

#classified-item #details {
  font-weight: 400;
  margin: 20px 0;
  clear:both;
}

#classified-form {
  h2 {
    padding-right:95px;
    @media(max-width:900px) { font-size:21px; }
    @media(max-width:600px) { font-size:19px; padding-right:45px; }
    @media(max-width:450px) { font-size:18px; }
  }
}

#page-mid #tabs ul#commentsstyle li {
  margin-bottom:18px;
}

#page-mid #calsfrm {
  .left {
    width:calc(50% - 11px);
    display:inline-block;
    vertical-align: top;
    @media(max-width:600px) {
      width:calc(100%);
    }
    .small {
      display:block;
      width:calc(100%);
    }
  }
  form {
    margin:0;
    input, select, textarea {
      margin: 0;
      width:100%;
    }
  }
  .msg {
    display:inline-block;
    margin-left:18px;
    vertical-align: top;
    width:calc(50% - 11px);
    @media(max-width:600px) {
      width:calc(100%);
      margin-left:0;
    }
    textarea {
      height:195px;
    }
  }
  #btn_enquiry_form {
    margin-top:12px;
  }
}

#classified-item {
  .price {
    background: #F4F4F4;
    width: 219px;
    min-height: 57px;
    border: 1px solid #D2D2D2;
    position: relative;
    margin: 20px 0;
    .left {
      color: #006B8C;
      display:inline-block;
      font-weight: 700;
      position: relative;
      padding: 9px 12px 0;
    }
    .right {
      color: #333;
      display:inline-block;
      font-size: 35px;
      position: relative;
      padding: 1px 20px 0 0;
    }

    @media(max-width:700px) {
      width:100%;
      float:none;
      .left {
        float:none;
      }
      .right {
        float:none;
      }
    }


  }
  #replybtn {
    background: url(../images/btn_reply.jpg) no-repeat;
    background-size:contain;
    width:100%;
    max-width: 221px;
    height: 36px;
    display: inline-block;
    text-indent: -9999px;
    cursor: pointer;
    position: relative;
    margin: 10px 0;
  }
}

#body-left .panel-wrap.district {
  margin: 0 0 35px;
  border: 1px solid #D2D2D2;
  border-radius: 3px;
  padding: 20px;
  background:#fff;
  h2 {
    color: #006B8C;
    font-size: 23px;
    position: relative;
    text-decoration: none;
    margin: 0 0 10px;
  }
  .more2 {
    color: #FFF !important;
    display: block;
    text-align: center;
  }
  ul.list-class {
    margin:15px -10px;
    padding:0;
    li {
      width:calc(33% - 21px);
      display:inline-block;
      margin: 0 10px 20px !important;
      min-height:218px;
      @media(max-width:750px) {
        width:calc(50% - 22px);
      }
      @media(max-width:600px) {
        width:calc(100% - 20px);
      }
      .txt-wrap {
        width:100%;
        display:block;
        h3 {
          min-height:65px;
        }
      }
    }
  }
  .left {
    float:none;
    width:100%;
    display:block;
  }
  .img-wrap {
    height: 100px;
    margin:0 auto;
    text-align:center;
    width:100%;
    overflow:hidden;
    img {
      width: auto !important;
      height:100px;
    }
  }
}

.lb-img {
  float: left;
  width: 120px;
  height: 88px;
  overflow: hidden;
  margin: 20px 20px 0 0;
}

.offers-page {
  background: #fff;
  border: 1px solid #D2D2D2;
  position: relative;
  margin: 30px 0 0;
  padding: 20px;
  vertical-align: top;
  #top {
    vertical-align: top;
    h1 {
      font-size:24px;
      line-height:1.3;
      display:inline-block;
      vertical-align: top;
      @media(max-width:900px) { font-size:21px; }
      @media(max-width:600px) { font-size:19px; }
      @media(max-width:450px) { font-size:18px; }
    }
    form {
      padding: 0 0 0 20px;
      display:inline-block;
      vertical-align: top;
    }
    fieldset {
      margin:0;
      display:inline-block;
      vertical-align: top;
      width:100%;
    }
    input, select {
      display:inline-block;
      vertical-align: top;
    }
    select {
      width:calc(100% - 110px);
    }
    input {
      background: url(../images/btn_green2.jpg) repeat-x scroll center top #719E29;
      padding: 10px 0;
      display:inline-block;
      vertical-align: top;
    }
    input.btngreen2 {
      display:inline-block;
      width:100%;
      vertical-align: top;
      margin:0;
      max-width:100px;
    }
  }
  .offers-wrap {
    border: none !important;
    li {
      width: calc(33.33% - 21px);
      border: 1px solid #D2D2D2 !important;
      position: relative;
      list-style: none;
      margin: 0 9px 20px;
      padding: 10px !important;
      display:inline-block;
      vertical-align: top;
      @media(max-width:960px) {
        width:calc(50% - 21px);
      }
      @media(max-width:540px) {
        width:calc(100% - 10px);
      }
      .view-btns {
        a.btn-viewoffer { width:100%; }
      }
    }
  }
  div.offers-wrap .img-wrap {
    height: 142px;
    @media(max-width:540px) {
      height:auto;
      text-align:center;
      img {
        width:auto;
        max-height:142px;
      }
    }
  }
}

.offers-left-panel {
  width: 170px;
  display:inline-block;
  margin-right: 15px;
  vertical-align:top;
  @media(max-width:660px) {
    width:100%;
    display:block;
    margin-right:0;
    margin-bottom:15px;
  }
}

.offers-right-panel {
  width: calc(100% - 190px);
  display:inline-block;
  vertical-align:top;
  ol, ul {
    margin: 0 0 20px 20px;
  }
  @media(max-width:660px) {
    width:calc(100%);
    display:block;
  }
}

.offer-img-wrap {
  //width: 390px;
  overflow: hidden;
  border: 1px solid #ccc;
  margin: 0 auto 15px;
  img {
    width:100%;
  }
}

.offerdet {
  h1 {
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    line-height:1.4;
    @media(max-width:900px) { font-size:23px; }
    @media(max-width:600px) { font-size:20px; }
    @media(max-width:450px) { font-size:18px; }
  }
  h4 {
    margin: 0 0 20px;
  }
}

.offerdetails {
  background: #CAE8F0;
  text-align: center;
  overflow: hidden;
  margin: 0 0 20px;
  padding: 10px;
  h3 {
    color: #000;
    font-size: 26px;
    font-weight: 700;
    text-shadow: 1px 1px 0 #fff;
  }
  .btngreen {
    cursor: pointer;
    font-size: 21px;
    width: 148px;
    margin: 10px 0;
    padding: 10px 0;
  }
  .info {
    background: #fff;
    position: relative;
    margin: 0 0 10px;
    padding: 3px 0;
  }
}

.offerbus {
  background: #eee;
  overflow: hidden;
  position: relative;
  padding: 10px;
  .btngreen {
    cursor: pointer;
    position: relative;
    text-align: center;
    color: #fff;
    margin: 10px 0;
    padding: 3px 0;
  }
}

#calander {
  position: relative;
  border: 1px solid #CCC !important;
  background: #fff;
  margin: 0 0 20px;
  padding: 10px;
  .btngreen {
    margin: 10px 0 0;
    width:100%;
  }
}

.loginright {
  margin: 10px auto;
  display:inline-block;
}

#page-wrap-inner .bck_login_bus {
  max-width: 282px;
  height: 128px;
  width:100%;
  background: url(../images/bck_login_bus.jpg) no-repeat;
  background-size:contain;
  position: relative;
  margin: 20px auto;
  vertical-align:top;
  a.packagesbtn {
    width: 142px;
    height: 37px;
    background: url(../images/btn_packagesbtn.jpg) no-repeat;
    position: absolute;
    top: 78px;
    left: 10px;
    text-indent: -9999px;
  }
  a.bussignbtn {
    width: 117px;
    height: 37px;
    background: url(../images/btn_bussignbtn.jpg) no-repeat;
    position: absolute;
    top: 78px;
    left: 155px;
    text-indent: -9999px;
  }
}

#page-wrap-inner .notmemberbck {
  max-width: 282px;
  height: 128px;
  width:100%;
  background: url(../images/notmemberbck.jpg) no-repeat;
  background-size:contain;
  position: relative;
  margin:10px auto;
  display:inline-block;
  vertical-align:top;
  a.bussignbtn {
    width: 117px;
    height: 37px;
    background: url(../images/btn_bussignbtn.jpg) no-repeat;
    position: absolute;
    top: 78px;
    left: 10px;
    text-indent: -9999px;
    display:inline-block;
  }
}

#login {
  legend {
    font-size: 16px;
    color: #006B8C;
  }
}

.mevent {
  background: url(../images/btn_suggest.jpg) no-repeat;
  position: relative;
  width: 175px;
  height: 78px;
  display: inline-block;
  text-indent: -9999px;
  cursor: pointer;
  margin: 0 20px 0 0;
  overflow:hidden;
}

.mclassified {
  background: url(../images/btn_classifieds.jpg) no-repeat;
  position: relative;
  width: 175px;
  height: 78px;
  display: inline-block;
  text-indent: -9999px;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.mdetails {
  background: url(../images/btn_details.jpg) no-repeat;
  position: relative;
  width: 175px;
  height: 78px;
  display: inline-block;
  text-indent: -9999px;
  cursor: pointer;
  margin: 0;
}

.mebus {
  background: url(../images/btn_editbus.jpg) no-repeat;
  position: relative;
  width: 175px;
  height: 78px;
  display: block;
  text-indent: -9999px;
  cursor: pointer;
  float: left;
  margin: 0 20px 0 0;
}

.mspecialo {
  background: url(../images/btn_especialo.jpg) no-repeat;
  position: relative;
  width: 175px;
  height: 78px;
  display: inline-block;
  text-indent: -9999px;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.mreview {
  background: url(../images/btn_reviews.jpg) no-repeat;
  position: relative;
  width: 175px;
  height: 78px;
  display: inline-block;
  text-indent: -9999px;
  cursor: pointer;
  margin: 0;
  vertical-align:top;
}

.mviews {
  background: url(../images/views.jpg) no-repeat;
  background-size:contain;
  display:inline-block;
  position:relative;
  width:100%;
  max-width: 275px;
  height: 51px;
  margin: 0 20px 20px 0;
  vertical-align:top;
  #number {
    position: absolute;
    top: 6px;
    left: 146px;
    font-size: 22px;
    color: red;
  }
  @media(max-width:600px) {
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
}

.yviews {
  background: url(../images/views2.png) no-repeat;
  background-size:contain;
  display:inline-block;
  width:100%;
  max-width: 275px;
  height: 51px;
  margin: 0 20px 20px 0;
  vertical-align:top;
  position:relative;
  @media(max-width:600px) {
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
  div {
    position: absolute;
    top: 6px;
    left: 131px;
    font-size: 22px;
    color: red;
  }
}

.menq {
  background: url(../images/menq.jpg) no-repeat;
  background-size:contain;
  position: relative;
  width:100%;
  max-width: 275px;
  height: 51px;
  display: inline-block;
  cursor: pointer;
  margin: 0 0 20px;
  vertical-align:top;
  #number {
    position: absolute;
    top: 6px;
    left: 98px;
    font-size: 22px;
    color: red;
  }
  @media(max-width:600px) {
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
}

.safe, .over {
  color: #FFF;
  font-weight: 700;
  font-size: 20px;
  padding: 3px;
}

.safe {
  color: green;
  font-size: 20px;
}

.over {
  color: red;
}

#addclassifieds {
  background: none repeat scroll 0 0 #EEE;
  border: 1px solid #CCC;
  margin: 0 0 20px;
  padding: 10px;
  #sell {
    position: relative;
    font-weight: 700;
    p {
      font-size: 16px;
    }
    ul {
      margin: 0px 20px 0 10px;
      width:calc(100% - 220px);
      display:inline-block;
      vertical-align:top;
      @media(max-width:600px) {
        width:100%;
        margin:0;
      }
    }
    li {
      font-size: 12px;
      font-weight: 200;
    }
    .sell100 {
      position: relative;
      width:180px;
      display:inline-block;
      color: #fff;
      background: #37b5dd;
      font-size: 40px;
      text-align: center;
      padding: 45px 10px 0 10px;
      height: 150px;
      line-height:32px;
      @media(max-width:600px) {
        display:block;
        margin:25px auto;
      }
    }
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  60% {
    opacity: .5;
  }
}


#addclassifieds {
  #sell .sell100 .dmall {
    font-size: 20px;
    clear: both;
  }
  #free {
    position: relative;
    width: 207px;
    height: 164px;
    padding: 0 0 0 20px;
  }
  #sell a {
    text-align: center;
    display:block;
    width:100%;
    margin-top:10px;
  }
  #free a {
    text-align: center;
    display:block;
    width:100%;
    margin-top:10px;
  }
}

.pricing-details-mem div {
  display:inline-block;
  width:32.5%;
  vertical-align: top;
  h4 {
    font-size: 18px !important;
    color: #333;
  }
  h5 {
    font-size: 12px;
    font-weight: 700;
    color: #069;
  }
  @media(max-width:460px) {
    width:100%;
    display:block;
    margin:5px 0;
  }
}

table#enquiries {
  text-align: left;
  width:100%;
}

#enquiries {
  th {
    text-align: left;
    padding:5px;
  }
  tr {
    position: relative;
    border: 1px solid #D2D2D2;
    overflow: hidden;
    margin: 0 0 10px;
    padding: 5px 10px;
    &:hover {
      background: #eee;
    }
  }
  td {
    padding:5px;
    vertical-align:top;
  }
  .enqdelete {
    background: url(../images/btn_deletem.jpg) no-repeat;
    position: relative;
    height: 30px;
    display: block;
    text-indent: -9999px;
    cursor: pointer;
    border: none;
    width:100% !important;
  }
  .mem_read {
    background: url(../images/btn_read.jpg) no-repeat;
    position: relative;
    width: 66px;
    height: 30px;
    display: inline-block;
    text-indent: -9999px;
    cursor: pointer;
  }
  .mem_new {
    background: url(../images/btn_new.jpg) no-repeat;
    position: relative;
    width: 74px;
    height: 30px;
    display: inline-block;
    text-indent: -9999px;
    cursor: pointer;
  }
  .mem_old {
    background: url(../images/btn_old.jpg) no-repeat;
    position: relative;
    width: 74px;
    height: 30px;
    display: inline-block;
    text-indent: -9999px;
    cursor: pointer;
  }
  form, fieldset {
    margin-bottom: 0;
    width: 77px;
  }
  .Actions {
    display:none;
    @media(max-width:600px) {
      display:block;
      text-align:center;
      padding:10px;
      form {
        margin:10px 0;
      }
    }
  }
  @media(max-width:600px) {
    .View,
    .Delete,
    .Status {
      display:none;
    }
  }
  .cname {
    font-weight: 700;
    font-size: 12px;
  }
  .cdate {
    font-style: italic;
    font-size: 10px;
    clear: both;
  }
}

.icon {
  background: url(../images/icons/ico_local.jpg) no-repeat;
}

#enqbusleft {
  width:calc(100% - 335px);
  position: relative;
  display:inline-block;
  vertical-align:top;
  margin-right:20px;
  .small input {
    width: 100%;
  }

  @media(max-width:900px) {
    width:100%;
    margin-right:0;
    margin-bottom:20px;
  }
  div.form-col {
    width:100%;
  }
}

#enqbusright {
  width: 309px;
  display:inline-block;
  vertical-align:top;
  border: 1px solid #D2D2D2;
  position: relative;
  background: none repeat scroll 0 0 #F4F4F4;
  padding: 20px;
  @media(max-width:900px) {
    width:100%;
    display:block;
  }
  p {
    margin: 0 0 15px;
  }
  img {
    float: left;
    margin: 10px 10px 10px 0;
  }
  .directions {
    width: 267px !important;
    text-align: center;
    float: left;
    position: relative;
    clear: both;
  }
  input.dirbt {
    text-align: center;
    position: relative;
    display:block;
    width:100%;
  }
}

.form-col .star_inner_wrap {
  margin: 4px 0;
}

.innertext {
  padding: 15px;
  img {
    float: left;
    margin: 0 20px 10px 0;
  }
}

.btn_classif1 {
  background: url(../images/btn_class1.jpg) no-repeat;
  width: 282px;
  height: 135px;
  text-indent: -9999px;
  display: block;
  position: relative;
  margin: 0 auto 30px;
}

.packagedetail {
  background: url(../images/pack_fade.jpg) no-repeat scroll center bottom transparent;
  overflow: hidden;
  display:block;
  margin: 0 0 30px;
  padding: 0 0 30px;
}

.imageleft {
  width: 138px;
  height: 148px;
  margin-right:10px;
  vertical-align:top;
  display:inline-block;
  @media(max-width:600px) {
    display:block;
    margin-right:auto;
    margin-left:auto;
    margin-bottom:15px;
  }
}

.textright {
  display:inline-block;
  vertical-align:top;
  width:calc(100% - 152px);
  @media(max-width:600px) {
    display:block;
    width:100%;
  }

}

.imageright {
  display:inline-block;
  width: 138px;
  height: 148px;
  vertical-align:top;
  @media(max-width:600px) {
    display:block;
    margin-right:auto;
    margin-left:auto;
    margin-bottom:15px;
  }
}

.textleft {
  vertical-align:top;
  display:inline-block;
  margin-left:10px;
  width:calc(100% - 152px);
  @media(max-width:600px) {
    display:block;
    width:100%;
    margin-left:0;
  }
}

.bronze_video {
  background: url(../images/bronze_vid.jpg) no-repeat top center;
  float: left;
  overflow: hidden;
  position: relative;
  width: 284px;
  height: 119px;
  text-indent: -9999px;
  margin: 0 0 30px;
}

.bronze_price1 {
  background: url(../images/bronze_price1.jpg) no-repeat top center;
  overflow: hidden;
  display:inline-block;
  max-width: 284px;
  background-size:contain;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
  width:100%;
}

.bronze_price2 {
  background: url(../images/bronze_price2.jpg) no-repeat top center;
  overflow: hidden;
  display:inline-block;
  background-size:contain;
  max-width: 284px;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
  width:100%;
}

.bronze_price3 {
  background: url(../images/bronze_price3.jpg) no-repeat top center;
  overflow:hidden;
  display:inline-block;
  background-size:contain;
  max-width: 284px;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

.silver_video {
  background: url(../images/silver_vid.jpg) no-repeat top center;
  display:inline-block;
  background-size:contain;
  overflow: hidden;
  width:100%;
  max-width: 284px;
  height: 119px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

.silver_price1 {
  background: url(../images/silver_price1.jpg) no-repeat top center;
  display:inline-block;
  background-size:contain;
  overflow: hidden;
  width:100%;
  max-width: 284px;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

.silver_price2 {
  background: url(../images/silver_price2.jpg) no-repeat top center;
  display:inline-block;
  background-size:contain;
  overflow: hidden;
  width:100%;
  max-width: 284px;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

.silver_price3 {
  background: url(../images/silver_price3.jpg) no-repeat top center;
  display:inline-block;
  background-size:contain;
  overflow: hidden;
  width:100%;
  max-width: 284px;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

.gold_video {
  background: url(../images/gold_vid.jpg) no-repeat top center;
  display:inline-block;
  background-size:contain;
  overflow: hidden;
  width:100%;
  max-width: 284px;
  height: 119px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

.gold_price1 {
  background: url(../images/untlimateprice.jpg) no-repeat top center;
  display:inline-block;
  background-size:contain;
  overflow: hidden;
  width:100%;
  max-width: 284px;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

.gold_price2 {
  background: url(../images/gold_price2.jpg) no-repeat top center;
  display:inline-block;
  background-size:contain;
  overflow: hidden;
  width:100%;
  max-width: 284px;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

.gold_price3 {
  background: url(../images/gold_price3.jpg) no-repeat top center;
  display:inline-block;
  background-size:contain;
  overflow: hidden;
  width:100%;
  max-width: 284px;
  height: 199px;
  text-indent: -9999px;
  margin: 0 auto 30px;
}

#star1, #star2, #star3, #star4, #star5 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  cursor: pointer;
}

#star1 {
  left: 0;
}

#star2 {
  left: 15px;
}

#star3 {
  left: 30px;
}

#star4 {
  left: 45px;
}

#star5 {
  left: 60px;
}

table.history {
  margin: 0 auto 20px;
  vertical-align:top;
  td, th {
    padding: 5px 10px;
    vertical-align:top;
  }
  tr.altRow {
    .sold { color: #fff; }
  }
}

.helpbtn {
  float: right;
  position: relative;
  right: 10px;
  top: -70px;
}

.helpbtn2 {
  float: right;
  position: relative;
  right: 10px;
  top: -94px;
  @media(max-width:650px) {
    float:none;
    right:auto;
    top:auto;
  }
}

#sthank {
  position: relative;
  float: left;
  overflow: hidden;
  width: 586px;
  height: 273px;
}

#slickbox {
  background: none repeat scroll 0 0 #fff;
}

ul#thumbnails {
  margin: 0 0 25px;
  li {
    list-style: none;
    width: 125px;
    height: 125px;
    float: left;
    border: 1px solid #D2D2D2;
    overflow: hidden;
    margin: 5px;
    text-align: center;
    img {
      height: 130px;
      border: 0;
    }
  }
}

fieldset, a img, #page-wrap ul#nav li.right {
  border: 0;
}

h3, h4, h5 {
  color: #006b8c;
  font-weight: 400;
}

#printlogo, .tabspace .offers-wrap .btn_viewall, .offers-page .offers-wrap .btn_viewall, #js_hide, .hidden {
  display: none;
}

#page-wrap ul#nav li.active {
  position: relative;
}

#enquiries {
  tbody, thead {
    position: relative;
  }
}

#page-wrap ul#nav a.active, #body-left .panel-wrap ul li a:hover, #page-footer-btm .links ul li a:hover {
  text-decoration: underline;
}

#page-mid, #body-main, .clear, .packagearea {
  clear: both;
}

#body-right #weather li img, .star_inner_wrap {
  position: relative;
}

#cats li:last-child, #hometabs ul li:last-child a {
  border: none;
}

#tabs ul#tab-items, #hometabs ul, #body-right .latestevents ul, #hometabs .nav li, #pagination {
  list-style: none;
}

ul {
  &#thumb-selection, &#img-preview {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.recpics, #addclassifieds h3 {
  padding: 0 0 10px;
}

#uploadform {
  h3, label {
    font-weight: 700;
    padding: 0 10px 10px;
  }
  h3 {
    margin:0;
  }
  form {
    margin:0;
  }
}

.tabinside li, #body-right .latestevents li {
  border-bottom: 1px solid #D2D2D2;
  position: relative;
  list-style: none;
  padding: 20px;
  display:flex;
}

.tabinside li .left, #body-right .latestevents li .left {
  border: 1px solid #D2D2D2;
  width: 45px;
  height: 45px;
  overflow: hidden;
  background: #fff;
  position: relative;
  margin: 0 20px 0 0;
  padding: 3px;
}

.tabinside li .right, #body-right .latestevents li .right {
  //width: 170px;
  position: relative;
}

.tabinside li .date {
  font-style: italic;
  font-size: 12px;
  text-decoration: none;
  color: #6E6E6E;
  a {
    font-style: italic;
    font-size: 12px;
    text-decoration: none;
    color: #6E6E6E;
  }
}

#body-right .latestevents li .date {
  font-style: italic;
  font-size: 12px;
  text-decoration: none;
  color: #6E6E6E;
}

.tabinside li {
  .date a {
    font-style: italic;
    font-size: 12px;
    text-decoration: none;
    color: #6E6E6E;
  }
  h3 {
    color: #333;
    font-size: 15px;
    text-decoration: none;
    a {
      color: #333;
      font-size: 15px;
      text-decoration: none;
    }
  }
}

#body-right .latestevents li h3, .tabinside li h3 a {
  color: #333;
  font-size: 15px;
  text-decoration: none;
}

#body-right .latestevents .tabbtm, #hometabs .tabbtm {
  height: 60px;
  position: relative;
}

div {
  &.classified-wrap .img-wrap img a, &.offers-wrap .img-wrap img a {
    width: 125px;
  }
  &.classified-wrap .txt-wrap h3, &.offers-wrap .txt-wrap h3 {
    font-size: 14px !important;
    color: #333 !important;
  }
}


.right2 .classified-wrap ul {
  margin-bottom: 0 !important;
}

.catspage {
  .classified-wrap ul, .offers-wrap ul {
    margin-bottom: 0 !important;
  }
}

.right2 .classified-wrap li {
  width: 260px;
  overflow: hidden;
  border: 0;
}

.catspage {
  .classified-wrap li, .offers-wrap li {
    width: 260px;
    overflow: hidden;
    border: 0;
  }
}

.right2 .classified-wrap .pricing-details {
  clear: both;
  width: 257px;
  border: 1px #ccc solid;
  overflow: hidden;
  margin: 0 auto 12px;
}

.catspage {
  .classified-wrap .pricing-details, .offers-wrap .pricing-details {
    clear: both;
    width: 257px;
    border: 1px #ccc solid;
    overflow: hidden;
    margin: 0 auto 12px;
  }
}

.right2 .classified-wrap .pricing-details div {
  float: left;
  height: 40px;
  text-align: center;
  width: 83px;
}

.catspage {
  .classified-wrap .pricing-details div, .offers-wrap .pricing-details div {
    float: left;
    height: 40px;
    text-align: center;
    width: 83px;
  }
}

.right2 .classified-wrap .img-wrap {
  width: 114px;
  display:inline-block;
  overflow: hidden;
  height: 95px;
  margin: 10px 0;
}

.catspage {
  .classified-wrap .img-wrap, .offers-wrap .img-wrap {
    width: 114px;
    overflow: hidden;
    height: 95px;
    margin: 10px 0;
  }
}

.right2 .classified-wrap .img-wrap img a {
  width: 120px;
}

.catspage {
  .classified-wrap .img-wrap img a, .offers-wrap .img-wrap img a {
    width: 120px;
    display:inline-block;
  }
}

ul.list-class .img-wrap img {
  width: 120px;
}

.right2 .classified-wrap .txt-wrap {
  width: calc(100% - 140px);
  margin: 10px;
  margin-right:0;
  display:inline-block;
}

.catspage {
  .classified-wrap .txt-wrap, .offers-wrap .txt-wrap {
    width: 105px;
    display:inline-block;
    margin: 10px;
  }
}

.right2 {
  .offers-wrap .titlepink, .classified-wrap .titlepink {
    height: 42px;
    position: relative;
    background: #CD0046;
    margin: 0 0 10px;
  }
}

.catspage {
  .offers-wrap .titlepink, .classified-wrap .titlepink {
    height: 42px;
    background: #CD0046;
    margin: 0 0 10px;
  }
}

.details #sub-item-txt div a, .offerbus a {
  color: #007BDC;
  text-decoration: underline;
}

.package li:first-child, .lastpack {
  background: none;
}

.contactinfo p, .extra p {
  font-size: 12px;
}

#classified-item .call, .pricing-details-mem {

  clear: both;
}

#calander form {
  margin: 0 !important;
}

#enquiries form {
  input {
    margin: 0 !important;
  }
  margin: 0;
  padding: 0;
  * {
    margin: 0;
    padding: 0;
  }
}

#gallery-homepage-overview {
  clear: both;
  overflow: hidden;
  background: #fff;
  //width: 300px;
  width:100%;
  border: 1px solid #ccc;
  margin: 0 0 20px 0;
  border-radius: 6px;
  .titlepink {
    background: #cd0046;
    color: #fff;
    height: 42px;
    margin: 0 0 10px;
  }
  h3 {
    color: #fff;
    float: left;
    height: 35px;
    padding: 3px 10px;
    font-size: 23px;
  }
  .paging {
    float: right;
    padding: 3px 10px;
    margin-top: 10px;
    a {
      cursor: pointer;
      background: url("../images/dot1.png") no-repeat scroll center top transparent;
      height: 14px;
      width: 14px;
      text-indent: -9999px;
      overflow: hidden;
      float: right;
      &.activeSlide {
        background: url("../images/dot2.png") no-repeat scroll center top transparent;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    width: 280px;
    min-height: 290px;
    overflow: hidden;
    list-style: none;
    position: relative;
    margin: 15px auto;
    li {
      margin: 0;
      width: 270px;
      min-height: 262px;
      .img-wrap {
        a {
          padding: 0;
          margin: 0;
        }
        width: 100%;
        height: 162px;
        overflow: hidden;
        img {
          border: 0;
          width: 100%;
        }
      }
      h4 {
        padding: 10px 0 14px;
      }
      a {
        color: #fff;
        padding: 0;
        height: 37px;
        line-height: 37px;
        text-align: center;
        font-weight: bold;
        &.btngreen {
          width: 45%;
          height: 36px;
        }
        &.viewall {
          background: url('../images/btn_blue_large.jpg') no-repeat;
          float: right;
          width: 50%;
          height: 39px;
        }
      }
    }
  }
}

#view-cat-details {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  margin: 0 20px 20px;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

#body-newsletter {
  background: #3ab9dc;
  /* Old browsers */
  background: -moz-linear-gradient(top, #3ab9dc 0%, #27a4d4 80%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3ab9dc), color-stop(80%, #27a4d4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #3ab9dc 0%, #27a4d4 80%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #3ab9dc 0%, #27a4d4 80%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #3ab9dc 0%, #27a4d4 80%);
  /* IE10+ */
  background: linear-gradient(to bottom, #3ab9dc 0%, #27a4d4 80%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3ab9dc', endColorstr='#27a4d4',GradientType=0 );
  /* IE6-9 */
  padding: 15px 25px 25px;
  border-radius: 6px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  margin: 0 0 30px;
  @media(max-width:600px) { padding-left:20px; padding-right:20px; }
  h3 {
    color: #fff;
    margin: 0;
    font-size: 27px;
    line-height:1.3;
    @media(max-width:900px) { font-size:24px; }
    @media(max-width:600px) { font-size:21px; }
    @media(max-width:450px) { font-size:19px; }
  }
  p {
    color: #fff;
    margin: 0;
    font-size: 14px;
  }
  .fleft {
    width: 100%;
  }
  .fright {
    width: 100%;
    padding-top: 15px;
    form {
      margin: 0;
    }
    input {
      vertical-align: top;
      &#txt_sub_email {
        width: calc(100% - 120px);
        display:inline-block;
        margin: 0;
      }
      &#btn_submit {
        width: 120px;
        display:inline-block;
        margin: 0;
        padding: 10px;
        position: relative;
        top: -1px;
      }
    }
  }
}

#body-newsletter2 {
  background: #3ab9dc;
  /* Old browsers */
  background: -moz-linear-gradient(top, #3ab9dc 0%, #27a4d4 80%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3ab9dc), color-stop(80%, #27a4d4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #3ab9dc 0%, #27a4d4 80%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #3ab9dc 0%, #27a4d4 80%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #3ab9dc 0%, #27a4d4 80%);
  /* IE10+ */
  background: linear-gradient(to bottom, #3ab9dc 0%, #27a4d4 80%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3ab9dc', endColorstr='#27a4d4',GradientType=0 );
  /* IE6-9 */
  padding: 15px 25px 25px;
  border-radius: 6px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  margin: 30px 0 0 0;
  h3 {
    color: #fff;
    margin: 0;
    font-size: 27px;
    @media(max-width:900px) { font-size:27px; }
    @media(max-width:600px) { font-size:23px; }
    @media(max-width:450px) { font-size:20px; }
  }
  p {
    color: #fff;
    margin: 0;
    font-size: 14px;
  }
  .fright {
    padding-top: 15px;
    form {
      margin: 0;
      max-width:600px;
      display:flex;
      @media(max-width:465px) {
        flex-direction:column;
      }
    }
    input {
      &#txt_sub_email {
        width:calc(100% - 140px);
        margin: 0;
        @media(max-width:465px) {
          width:100%;
        }
      }
      &#btn_submit {
        width: 140px;
        margin: 0;
        padding: 10px;
        top: -1px;
        @media(max-width:465px) {
          width:100%;
        }
      }
    }
  }
}

/*
#page-header-search{position:absolute;right:0;top:53px}
#page-header-search .mainsearch{background:#FFF;border:1px solid #ACADB2;color:#999;float:left;position:relative;width:160px;margin:0 11px 0 0;padding:12px}
#page-header-search .btn_search{background:url(../images/btn_search.jpg) no-repeat center top;width:119px;height:42px;text-indent:-9999px;display:block;position:relative;float:left;border:none}
*/

#page-header-search {
  position: relative;
  border-radius: 6px;
  padding: 20px;
  border: 1px #ccc solid !important;
  margin-bottom:35px;
  background: rgba(146, 187, 57, 1);
  background: -moz-linear-gradient(top, rgba(146, 187, 57, 1) 0%, rgba(94, 144, 19, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(146, 187, 57, 1)), color-stop(100%, rgba(94, 144, 19, 1)));
  background: -webkit-linear-gradient(top, rgba(146, 187, 57, 1) 0%, rgba(94, 144, 19, 1) 100%);
  background: -o-linear-gradient(top, rgba(146, 187, 57, 1) 0%, rgba(94, 144, 19, 1) 100%);
  background: -ms-linear-gradient(top, rgba(146, 187, 57, 1) 0%, rgba(94, 144, 19, 1) 100%);
  background: linear-gradient(to bottom, rgba(146, 187, 57, 1) 0%, rgba(94, 144, 19, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#92bb39', endColorstr='#5e9013', GradientType=0 );
  h3 {
    color: #FFFFFF;
    font-size: 20px;
    line-height:1.3;
    @media(max-width:900px) { font-size:19px; }
    @media(max-width:600px) { font-size:18px; }
    @media(max-width:450px) { font-size:17px; }
  }
  form {
    margin:0;
    fieldset {
      margin-bottom:0;
    }
  }
  .mainsearch {
    background: #FFF;
    border: 1px solid #ACADB2;
    color: #999;
    position: relative;
    width:100%;
    //max-width: calc(100% - 261px);
    margin: 10px 0;
    padding: 12px;
    display:inline-block;
    @media(max-width:960px) {
      width: calc(100% - 261px);
    }
    @media(max-width:600px) {
      width: calc(100%);
    }
  }
  .btn_search {
    background: url(../images/btn_search.jpg) no-repeat center top;
    height: 42px;
    text-indent: -9999px;
    display:inline-block;
    border: none;
    max-width: 257px;
    width:100%;
    @media(max-width:600px) {
      margin-left:auto;
      margin-right:auto;
      display:block;
    }
  }
}


.center {
  text-align:center;
}

.full-width {
  width:100% !important;
}



.vertical-align {
  display:flex;
  align-items:center;
}

.side-by-side-tablet {
  @media(max-width:960px) {
    display:flex;
    justify-content: space-between;
    align-content: space-between;
    @media(max-width:650px) {
      flex-direction:column;
    }
    .offers-wrap, .classified-wrap, #place-your-ads, #districtguide, .suggest {
      width:calc(50% - 10px);
      @media(max-width:650px) {
        width:calc(100%);
      }
    }
  }
}
